import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import ButtonSolid from "../Button/ButtonSolid";
import ButtonGhost from "../Button/ButtonGhost";

const CTA = ({
  heading,
  headingLevel,
  className,
  phonenumber,
  description,
}) => {
  const HeadingTag = headingLevel || "h2";
  const flower = "../../images/0.0 Repeated Modules/Decal/BayView Decal.svg";
  return (
    <section className="bg-white p-[15px]  md:min-w-full">
      <div className="container my-[56px] rounded-3xl bg-transparent md:my-0 md:rounded-none ">
        <div className="">
          <StaticImage src={flower} />
        </div>
        <section
          className={` ${
            className || "p-[15px]  text-center md:text-left lg:py-26"
          }`}
        >
          <div className="mx-auto grid md:grid-cols-1">
            <div className=" mx-auto text-center lg:max-w-[65%]">
              <HeadingTag className="mb-4 font-medium">
                {heading || ["Get More Info or Plan a Visit"]}
              </HeadingTag>

              {description ? (
                <p className="lg:text-xl">{description}</p>
              ) : (
                <p className="">
                  We’re here to answer your questions and would love to show you
                  around if you’re interested in scheduling a tour of BayView
                  Senior Assisted Living.
                </p>
              )}
            </div>
            <div className="flex flex-col items-center justify-center space-y-4 md:flex-row md:flex-row md:space-y-0 md:space-x-4">
              <ButtonGhost
                href="tel:(619) 225-5616"
                text={phonenumber}
                className="font-semibold"
              />
              <ButtonSolid
                modal="modal-contact"
                text="Contact"
                className="font-semibold"
              />
            </div>
          </div>
        </section>
      </div>
    </section>
  );
};

export default CTA;
